import React from 'react';
import styles from './VerticalPadding.module.css';

export const VerticalPadding = ({ paddingSizeType = "medium", children, className = "padding" }) => {
    const paddingClass = styles[`padding-${paddingSizeType}`];

    return (
        <div className={`${paddingClass} ${className}`}>
            {children}
        </div>
    );
};
