import axios from 'axios'
import { parseApiError } from '../utils/parsers/parseApiError';

export const login = async (bodyFormData) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_CLOUD_IB_BASE_URL_ANALYTICS_FAST_API}/${process.env.REACT_APP_CLOUD_IB_ENDPOINT_POST_LOGIN}`,
            bodyFormData,
            { 
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true 
            }
        )

        return {
            success: true,
            ...response.data
        }
        
    } catch (error) {
        return parseApiError(error)
    }
}