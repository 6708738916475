import React from 'react';
import styles from './GridListBox.module.css';

export const GridListBox = ({ data, isEnabled }) => {
    const gridItems = data.map((columns, index) => {
        const args = columns.slice(0, -1)
        const last = columns[columns.length - 1]
        return (
            <div key={index} className={styles["grid-item"]}>
                {args.map((arg, idx) => (
                    <div key={idx} className={`${styles["grid-name-base"]} ${styles["grid-name-default"]}`}>{arg}</div>
                ))}
                <a href={last} target="_blank" rel="noopener noreferrer" className={`${styles["grid-name-base"]} ${styles["grid-name-link"]}`}>
                    {last}
                </a>
            </div>
        )
    })

    const shouldDisplayEmptyDiv = data.length === 0
    const shouldScroll = data.length > 9

    if (shouldDisplayEmptyDiv) {
        return (
            <div className={styles["empty"]}> </div>
        )
    }

    return (
        <div className={styles["grid-listbox-container-wrapper"]}>
            <div className={`${styles["grid-listbox-container"]} ${!isEnabled ? styles.disabled : ""}`}>
                <div className={shouldScroll ? styles["scroll-pane"] : ""}>
                    {gridItems}
                </div>
            </div>
        </div>
    )
};
