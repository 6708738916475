// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadDataPage_title__S1bk- {
    text-align: center;
    margin: 20px 0;
    font-size: 18px;
    font-weight: 300; 
    color: #555;
    font-family: 'Helvetica Neue', Arial, sans-serif; 
    line-height: 1.5; 
}

.LoadDataPage_primary-text__YzjbI {
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
    color: #555; 
    font-family: 'Helvetica Neue', Arial, sans-serif; 
    font-size: 1.5rem;
    font-weight: 600; 
    line-height: 1.5; 
}

.LoadDataPage_horizontal-padding__gietg {
    padding-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/LoadDataPage/LoadDataPage.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,gDAAgD;IAChD,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,gDAAgD;IAChD,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".title {\n    text-align: center;\n    margin: 20px 0;\n    font-size: 18px;\n    font-weight: 300; \n    color: #555;\n    font-family: 'Helvetica Neue', Arial, sans-serif; \n    line-height: 1.5; \n}\n\n.primary-text {\n    margin-bottom: 20px;\n    text-align: center;\n    width: 100%;\n    color: #555; \n    font-family: 'Helvetica Neue', Arial, sans-serif; \n    font-size: 1.5rem;\n    font-weight: 600; \n    line-height: 1.5; \n}\n\n.horizontal-padding {\n    padding-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `LoadDataPage_title__S1bk-`,
	"primary-text": `LoadDataPage_primary-text__YzjbI`,
	"horizontal-padding": `LoadDataPage_horizontal-padding__gietg`
};
export default ___CSS_LOADER_EXPORT___;
