import styles from './CompanyHeaderPanel.module.css';

export const CompanyHeaderPanel = ({
  data,
  onDataChange,
  onClick,
  width = "100%",   
  height = "auto",  
  otherStyles = {},  
}) => {
  const panelStyles = {
    width: width,
    height: height,
    ...otherStyles, 
  }

  return (
    <div className={styles["panel"]} style={panelStyles}>
      <h4 className={styles["title"]}>Company Details</h4>
      {data && typeof data === "object" && Object.entries(data).length > 0 ? (
        Object.entries(data).map(([key, [label, value]]) => (
          <div key={key} className={styles["field"]}>
            <span className={styles["label"]}>{label}:</span>
            <input
              className={styles["input"]}
              type="text"
              value={value}
              onChange={(e) => onDataChange(key, e)}
              readOnly
            />
            <button 
              className={styles["selectButton"]}
              onClick={() => onClick(value)} 
            ></button>
          </div>
        ))
      ) : (
        <div className={styles["field"]}>
          <span className={styles["label"]}>No company selected.</span>
        </div>
      )}
    </div>
  )  
}
