import {
    SET_COMPANY_INPUT_VALUE,
    SET_SELECTED_CORRECT_LOCATIONS,
    SET_COMMENT_LOCATIONS_INPUT_VALUE,
    ON_BUTTON_SUBMIT_TO_DATABASE,
} from './headerActionTypes';

const initialState = {
    companyInputValue: "Company",
    selectedCorrectLocations: "Select Locations?",
    commentLocationsInputValue: "",
    buttonLabel: "Submit To Database",
    comboBoxLabel: "",
    isCompanyListBoxEnabled: true,
    correctLocationsOptions: [],
    isCommentLocationsInputValueVisible: false,
    callbacks: {
        toggleCompanyListBox: () => {},
        onButtonSubmitToDatabase: () => {},
    },
}

export const headerReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COMPANY_INPUT_VALUE:
            return {
                ...state,
                companyInputValue: action.payload.value,
                isCompanyListBoxEnabled: action.payload.flag,
                toggleCompanyListBox: action.payload.callbackFlag
            }
        case SET_SELECTED_CORRECT_LOCATIONS:
            return {
                ...state,
                comboBoxLabel: action.payload.label,
                correctLocationsOptions: action.payload.options,
                selectedCorrectLocations: action.payload.value,
            }
        case SET_COMMENT_LOCATIONS_INPUT_VALUE:
            return {
                ...state,
                commentLocationsInputValue: action.payload.value,
                isCommentLocationsInputValueVisible: action.payload.flag
            }
        case ON_BUTTON_SUBMIT_TO_DATABASE:
            return {
                ...state,
                callbacks: {
                    ...state.callbacks,
                    buttonLabel: action.payload.label,
                    onButtonSubmitToDatabase: action.payload.callback,
                },
            }
        default:
            return state
    }
}
