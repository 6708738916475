import React, { useMemo } from 'react';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import styles from './MapBox.module.css';

export const MapBox = ({ center, zoom, coordinates, icon }) => {
    const markers = useMemo(() => {
        return coordinates.map((coords, index) => (
            <Marker key={index} position={coords} icon={icon} />
        ))
    }, [coordinates, icon])

    return (
        <div className={styles['map-container']}>
            <MapContainer
                center={center}
                zoom={zoom}
                style={{ height: "200%", width: "200%" }}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {markers}
            </MapContainer>
        </div>
    );
}
