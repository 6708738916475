import React, { useState } from 'react'
import styles from './PopupPage.module.css'
import { toast, ToastContainer } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { FancyPasswordInput } from '../../components/FancyPasswordInput/FancyPasswordInput'
import { VerticalPadding } from '../../components/VerticalPadding/VerticalPadding'
import { IgrMultiColumnComboBoxModule, IgrMultiColumnComboBox } from 'igniteui-react-grids';
import { comboBoxUsernameOptions } from '../../utils/comboBoxOptions/userOptions'
import { login } from '../../services/users'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'

export const PopupPage = () => {
    IgrMultiColumnComboBoxModule.register()
    const navigate = useNavigate()

    const [openModal, setOpenModal] = useState(true)
    const [password, setPassword] = useState("")
    const [selectedUsernameInformation, setSelectedUsernameInformation] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const isPasswordLengthValid = (password, minLength = 3) => {
        return password.length >= minLength
    }

    const isUsernameInformationSelected = (usernameInformation) => {
        return Object.keys(usernameInformation).length > 0
    }

    const handleInvalidData = (errorMsg, setterKey, setterValue = "") => {
        toast.error(errorMsg)
        setterKey(setterValue)
    }

    const attemptUserLogin = async (credentials, { setIsLoading }) => {
        const username = credentials.username
        const password = credentials.password

        const params = {
            payload: {
                username: username,
                password: password
            }
        }

        setIsLoading(true)
        const loginResponse = await login(params)
        setIsLoading(false)
       
        if(!loginResponse.success) {
            return {
                consoleError: JSON.stringify(loginResponse),
                toastError: "Incorrect username or password.",
            }
        }

        if(loginResponse?.detail) {
            return {
                consoleError: loginResponse.detail,
                toastError: loginResponse.detail
            }
        }

        return {
            jwt: ""
        }
    }

    const handleNavigation = async (path) => {
        if(!isUsernameInformationSelected(selectedUsernameInformation)) {
            handleInvalidData(
                "Username is not selected. Please select it to move forward.",
                setSelectedUsernameInformation,
                {}
            )
            return
        }

        const username = selectedUsernameInformation.username

        const credentials = {
            username: username.toLowerCase(),
            password: password
        }
        const userLoginData = await attemptUserLogin(credentials, { setIsLoading })

        if(userLoginData?.toastError) {
            console.error(`Error: ${userLoginData.consoleError}`)
            toast.error(userLoginData.toastError)
            return
        }

        setOpenModal(false)
        navigate(
            path,
            { state: { 
                id: selectedUsernameInformation.id,
                username: username,
            } 
        })
    }

    const onButtonLogin = async () => await handleNavigation("data_selection")

    const onButtonX = () => {
        setOpenModal(false)
        window.close()
    }

    const handleInputChange = (setter) => (event) => {
        setter(event.target.value)
    }

    if (!openModal) {
        return (
            <div></div>
        )
    }

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div className={styles["modalBackground"]}>
            <div className={styles["modalContainer"]}>
                <div className={styles["titleCloseBtn"]}>
                    <button
                        onClick={onButtonX}
                    >
                        &times;
                    </button>
                </div>
                <div className={styles["title"]}>
                    <h1>Ready to Load Batch Data</h1>
                </div>
                <div>
                    <IgrMultiColumnComboBox 
                        width="400px" 
                        height="50px" 
                        defaultColumnWidth={400}
                        placeholder="Select select your name"
                        dataSource={comboBoxUsernameOptions}
                        selectedValueChanged={ async (s, e) => {
                            const selectedUsername = e._implementation.b
                            const id = comboBoxUsernameOptions.findIndex(option => option.username === selectedUsername) + 1
                            const selectedUsernameInformation = {
                                "username": selectedUsername,
                                "id": id
                            }
                            setSelectedUsernameInformation(selectedUsernameInformation)
                        }}
                        actualLabelVisible={false}
                    />
                    <VerticalPadding 
                        paddingSizeType="small" 
                    />
                    <div className={styles["instruction"]}>  
                        <h4>Please enter the password to continue.</h4>
                    </div>
                    <FancyPasswordInput
                        value={password}
                        onChange={handleInputChange(setPassword)}
                    />   
                </div>
                <div className={styles["login"]}>
                    <button
                        onClick={onButtonLogin}
                        disabled={!isPasswordLengthValid(password) || !isUsernameInformationSelected(selectedUsernameInformation)}
                    >Login</button>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}


