import React from 'react';
import styled from 'styled-components';

const ComboBoxContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center; 
    padding: 10px;
    width: 100%; 
    box-sizing: border-box; 
    position: relative; 
    z-index: 1000; 
`;

const StyledSelect = styled.select`
    width: ${(props) => {
        if (typeof props.width === 'string' && props.width.endsWith('%')) {
            return `calc(${props.width} * 230px / 100)`;
        }
        return JSON.stringify(props.width) || '230px'; 
    }}; 
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 12px;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    background-color: #fff;
    appearance: none; 
    cursor: pointer;

    &:focus {
        border-color: #9c9b9b;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        outline: none;
    }

    &::-ms-expand {
        display: none; 
    }

    background: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 6"%3E%3Cpath d="M5 5L1 1h8L5 5z" fill="%239c9b9b" /%3E%3C/svg%3E') no-repeat right 10px center;
    background-size: 12px 7px;
`;

export const FancyComboBox = ({ 
    id = "id", 
    starterText, 
    options, 
    value, 
    onChange, 
    width,
    customStyles
}) => {
     return (
        <ComboBoxContainer>
            <StyledSelect 
                id={id} 
                value={value} 
                onChange={onChange} 
                width={width} 
                style={customStyles}
            >
                <option value="" disabled>{starterText}</option>
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </StyledSelect>
        </ComboBoxContainer>
    )
}