export class NoSuchAuthTokenError extends Error {
    constructor(message, errorCode) {
        super(message)
        this.errorCode = errorCode
    }
}

export class NoSuchDimensionError extends Error {
    constructor(message, errorCode) {
        super(message);
        this.name = 'NoSuchDimensionError';
        this.errorCode = errorCode;
    }
}

export class NoSuchDataFormatAvaiableError extends Error {
    constructor(message) {
        super(message);
        this.name = 'NoSuchDataFormatAvaiableError';
    }   
}

export class NoSuchFileAvailableError extends Error {
    constructor(message) {
        super(message);
        this.name = 'NoSuchFileAvailableError';
    }
}

export class NoSuchReadTypeAvailable extends Error {
    constructor(message) {
        super(message);
        this.name = 'NoSuchReadTypeAvailable';
    }
}