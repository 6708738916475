import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CompanyListBox } from '../../components/HomePage/CompanyListBox/CompanyListBox'
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner'
import { DataTranformer } from '../../utils/transformations'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { MapBox } from '../../components/HomePage/MapBox/MapBox'
import { BlueMapIcon } from '../../utils/icons/BlueMapIcon'
import { 
    defaultScreenCenter, 
    defaultZoom, 
    databaseSubmitLabel, 
    companyLocationsFilteredFieldNames
} from '../../utils/constants'
import { correctLocationsOptions, correctUrlOptions } from '../../utils/comboBoxOptions/correctOptions'
import styles from './HomePage.module.css'
import { submitAnalyticsData } from '../../services/analytics'
import { VerticalPadding } from '../../components/VerticalPadding/VerticalPadding'
import { 
    correctUrlOptionsConvertor, 
    correctLocationsOptionsConvertor, 
} from '../../utils/comboBoxOptions/convertors/fromKeyToValue'
import { FancyButton } from '../../components/FancyButton/FancyButton';
import { GridListBox } from '../../components/GridListBox/GridListBox'
import { IFrameBox } from '../../components/IFrameBox/IFrameBox'
import { resetToDefault } from '../../utils/helpers/resetToDefault'
import { useNavigate } from 'react-router-dom'
import { handleSpecificInputChange } from '../../utils/helpers/handleSpecificInputChange'
import { headerList } from '../../components/Header/HeaderList/HeaderList'
import { 
    topHomePageRows, 
    topDownHomePageRows,
    downHomePageRows 
} from '../../utils/helpers/homePageRows'
import { headerActions } from '../../state/header/headerActions'
import { 
    onButtonOpenCompanyLocationsPage,
    onButtonSubmitToDatabaseHelper
} from '../../utils/helpers/callbacks'
import { ListRow } from '../../components/ListRow/ListRow'
import { crawlerOptions } from '../../utils/comboBoxOptions/crawlerOptions'
import { getLabelByValue } from '../../utils/comboBoxOptions/convertors/labelByValue'
import { Companies } from '../../models/companies'
import { handleFileLoad, onSelectedCompanyHelper } from '../../utils/helpers/pages/homePageHelpers'
import { onButtonDownloadAnalyticsCSVHelper } from '../../utils/helpers/pages/homePageHelpers'
import { 
    selectUsername,
    selectBatchID,
    selectUserID,
    selectFileName,
    selectFileLoader,
    selectFileContent,
    selectInsertBatch
} from '../../state/session/sessionSelectors'
import { setInsertBatch }  from '../../state/session/sessionActions'
import { setBatchID } from '../../state/session/sessionActions'

export const HomePage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const username = useSelector(selectUsername)
    const batchID = useSelector(selectBatchID)
    const userID = useSelector(selectUserID)
    const fileName = useSelector(selectFileName)
    const fileLoader = useSelector(selectFileLoader)
    const fileContent = useSelector(selectFileContent)
    const canInsertBatch = useSelector(selectInsertBatch)

    const batchRef = useRef(batchID) 
    const setNewBatchID = (newBatchID) => {
        batchRef.current = newBatchID
    }
    const getNewBatchID = () => {
        return batchRef.current
    }

    const insertBatchRef = useRef(canInsertBatch)
    const disableBatchInsert = () => {
        insertBatchRef.current = false
    }

    const [companyID, setCompanyID] = useState(null)
    const [companies, setCompanies] = useState([])
    const [companyLocationsInformation, setCompanyLocationsInformation] = useState([])
    const [selectedCompany, setSelectedCompany] = useState("")
    const [isLoading, setIsLoading] = useState(true);
    const [mapCoordinates, setMapCoordinates] = useState([])
    const [companyInputValue, setCompanyInputValue] = useState("")
    const [selectedCorrectUrl, setSelectedCorrectUrl] = useState("")
    const [commentUrlInputValue, setCommentUrlInputValue] = useState("")
    const [selectedCorrectLocations, setSelectedCorrectLocations] = useState("")
    const [commentLocationsInputValue, setCommentLocationsInputValue] = useState("")
    const [isCompanyListBoxEnabled, setIsCompanyListBoxEnabled] = useState(true)
    const [iFrameUrl, setIFrameUrl] = useState(null)
    const [isCommentUrlInputValueVisible, setIsCommentUrlInputValueVisible] = useState(false)
    const [isCommentLocationsInputValueVisible, setIsCommentLocationsInputValueVisible] = useState(false)
    const [selectedCrawlerOption, setSelectedCralwerOption] = useState("")

    useEffect(() => {
        const loaderParams = {
            setterIsLoading: setIsLoading,
            setterCompanies: setCompanies,
            createBatchParams: {
                payload: {
                    name: fileName,
                    uploaded_by: userID,
                    content: fileContent,
                }
            },
            batchID: batchID,
            setNewBatchID: setNewBatchID,
            getNewBatchID: getNewBatchID,
            insertBatchRef: insertBatchRef,
            disableBatchInsert: disableBatchInsert,
            dispatch: dispatch,
            setBatchID: setBatchID,
            setInsertBatch: setInsertBatch,
            canInsertBatch: false
        }

        handleFileLoad(fileLoader, loaderParams)

    }, [fileName, fileLoader, fileContent, userID, batchID, isCompanyListBoxEnabled, dispatch])

    const onSelectedCompany = async (company) => {
        onSelectedCompanyHelper(company, {
            setMapCoordinates: setMapCoordinates,
            setCompanyLocationsInformation: setCompanyLocationsInformation,
            setCompanyInputValue: setCompanyInputValue,
            setIFrameUrl: setIFrameUrl,
            setCompanyID: setCompanyID,
            setSelectedCompany: setSelectedCompany,
            handleCompanyListBox: handleCompanyListBox,
            setIsCompanyListBoxEnabled: setIsCompanyListBoxEnabled
        })
    }

    const handleInputChange = (setter) => (event) => {
        const { id, value } = event.target
        const callbacks = [setIsCommentUrlInputValueVisible, setIsCommentLocationsInputValueVisible]
        handleSpecificInputChange(id, value, ...callbacks)
        setter(value)
    }

    const onButtonSubmitToDatabase = async () => {
        await onButtonSubmitToDatabaseHelper({
            companyID: companyID,
            urlReviewCategory: correctUrlOptionsConvertor[selectedCorrectUrl],
            urlReviewComment: commentUrlInputValue,
            locationsReviewCategory: correctLocationsOptionsConvertor[selectedCorrectLocations],
            locationsReviewComment: commentLocationsInputValue,
            createdBy: userID,
            submitFunction: submitAnalyticsData,
            resetFunction: resetToDefault,
            setCompanyLocationsInformation: setCompanyLocationsInformation,
            defaultCompanyLocationsInformation: [],
            defaultValue: "",
            resetArgs: [
                setCompanyInputValue, 
                setSelectedCorrectUrl, 
                setCommentUrlInputValue, 
                setSelectedCorrectLocations, 
                setCommentLocationsInputValue,
            ]
        })
    }

    const onButtonDownloadAnalyticsCSV = async (event) => {
        await onButtonDownloadAnalyticsCSVHelper(event, { userID, batchID: getNewBatchID() })
    }

    const handleCompanyListBox = () => {
        onCompayListBox(setIsCompanyListBoxEnabled)
    }

    const onCompayListBox = (setCompayListBoxFlag) => {
        setCompayListBoxFlag(!isCompanyListBoxEnabled)
    }

    const topHomePageParams = [
        [
            [companyInputValue, handleInputChange(setCompanyInputValue), isCompanyListBoxEnabled, handleCompanyListBox],
            ["comment-url-combo-box", correctUrlOptions, selectedCorrectUrl, handleInputChange(setSelectedCorrectUrl)],
            [commentUrlInputValue, handleInputChange(setCommentUrlInputValue), isCommentUrlInputValueVisible]
        ],
        [
            [username, null, false],
            ["comment-locations-combo-box", correctLocationsOptions, selectedCorrectLocations, handleInputChange(setSelectedCorrectLocations)],
            [commentLocationsInputValue, handleInputChange(setCommentLocationsInputValue), isCommentLocationsInputValueVisible]
        ],
    ]

    const topDownHomePageParams = [
        [
            [databaseSubmitLabel, onButtonSubmitToDatabase],
            ["AI Locations Check", () => onButtonOpenCompanyLocationsPage({
                urls: companyLocationsInformation,
                crawlerType: getLabelByValue(crawlerOptions, selectedCrawlerOption),
                iFrameUrl: iFrameUrl,
                navigate: navigate,
                location: "/company_locations",
                dispatch: dispatch,
                actions: headerActions,
                params: [
                    [companyInputValue, !isCompanyListBoxEnabled, handleCompanyListBox],
                    ["comment-locations-combo-box", correctLocationsOptions, selectedCorrectLocations],
                    [commentLocationsInputValue, isCommentLocationsInputValueVisible],
                    [databaseSubmitLabel, onButtonSubmitToDatabase],
                ]})
            ]
        ]
    ]

    const downHomePageParams = [
        [
            ["crawler-combo-box", crawlerOptions, selectedCrawlerOption, handleInputChange(setSelectedCralwerOption)],
            [],
            []
        ]
    ]

    const gridListBoxData = DataTranformer.toDataGridListBoxPresenter(
        companyLocationsInformation,
        companyLocationsFilteredFieldNames
    )

    if (isLoading) {
        return <LoadingSpinner />;
    }

    return (
        <div>
            <VerticalPadding paddingSizeType="small" />
            <div>
                {headerList(
                    [topHomePageRows, topDownHomePageRows, downHomePageRows],
                    [topHomePageParams, topDownHomePageParams, downHomePageParams],
                    [
                        { container: "top-flex-container", row: "top-flex-row", item: "top-flex-item" }, 
                        { container: "top-down-grid-container", row: "top-down-grid-row", item: "top-down-grid-item" },
                        { container: "top-flex-container", row: "top-flex-row", item: "top-flex-item" } 
                    ]
                ).map((headerItem, index) => (
                    <div key={index}> {headerItem}</div> 
                ))}
            </div>

            <VerticalPadding paddingSizeType="small" />
            <div className={`styles["company-listbox-container"] styles["comment-padding"]`}>
                <div className={styles["center-container"]}>
                    <CompanyListBox
                        companies={Companies.getAllCompaniesForListBox(companies)}
                        selectedCompany={selectedCompany}
                        onChange={onSelectedCompany}
                        isEnabled={!isCompanyListBoxEnabled}
                    />
                </div>
            </div>
            <VerticalPadding paddingSizeType="small" />
            <ListRow 
                components={[
                    <MapBox
                        center={defaultScreenCenter}
                        zoom={defaultZoom}
                        coordinates={mapCoordinates}
                        icon={BlueMapIcon}
                    />,
                    <IFrameBox
                        src={iFrameUrl}
                        title="iframe-link-displayer"
                        width="80vw"
                        height="70vh"
                    /> 
                ]}
                data={[mapCoordinates, iFrameUrl]}
            />
            <VerticalPadding paddingSizeType="small" />
            { 
                Array.isArray(gridListBoxData) 
                    ? (gridListBoxData.length === 0 
                        ? toast.error("Problems with grid list box component") 
                        : <GridListBox
                            data={gridListBoxData}
                            isEnabled={!isCompanyListBoxEnabled}
                        />
                    ) 
                    : null 
            }
            <VerticalPadding paddingSizeType="small" />
            <div className={styles["center-flex-button"]}>
                <FancyButton
                    onClick={onButtonDownloadAnalyticsCSV}
                    bgColor="#808080"
                    hoverBgColor="#000000"
                    width="100%"
                >
                    Download Analytics CSV
                </FancyButton>
            </div>
            <ToastContainer />
        </div>
    )
}

