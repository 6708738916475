import { configureStore } from '@reduxjs/toolkit';
import { headerReducer } from './headerReducer';
import { createSerializableStateInvariantMiddleware } from '@reduxjs/toolkit';

const serializableMiddleware = createSerializableStateInvariantMiddleware({
  isSerializable: () => true, 
})

export const headerStore = configureStore({
  reducer: headerReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, 
    }).concat(serializableMiddleware), 
})

