export class StringFormatter  {
    static toSnakeCase(input) {
        return input
            .trim()
            .toLowerCase()
            .replace(/\s+/g, '_')
    }

    static toGridFormat(input) {
        if (!input.includes('_')) {
            return StringFormatter.#capitalizeFirstLetter(input)
        }

        return input
            .split('_')
            .map(word => StringFormatter.#capitalizeFirstLetter(word)) 
            .join(' '); 
    }

    static #capitalizeFirstLetter(word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }
}