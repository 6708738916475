// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VerticalPadding_padding-small__3F2sg {
    padding-top: 25px;
    padding-bottom: 25px;
}

.VerticalPadding_padding-medium__KPqs\\+ {
    padding-top: 50px;
    padding-bottom: 50px;
}

.VerticalPadding_padding-large__Q0Wca {
    padding-top: 100px;
    padding-bottom: 100px;
}`, "",{"version":3,"sources":["webpack://./src/components/VerticalPadding/VerticalPadding.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;AACzB","sourcesContent":[".padding-small {\n    padding-top: 25px;\n    padding-bottom: 25px;\n}\n\n.padding-medium {\n    padding-top: 50px;\n    padding-bottom: 50px;\n}\n\n.padding-large {\n    padding-top: 100px;\n    padding-bottom: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"padding-small": `VerticalPadding_padding-small__3F2sg`,
	"padding-medium": `VerticalPadding_padding-medium__KPqs+`,
	"padding-large": `VerticalPadding_padding-large__Q0Wca`
};
export default ___CSS_LOADER_EXPORT___;
