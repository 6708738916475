// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadingSpinner_loading-container__clMX\\+ {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    width: 100vw; 
    position: fixed;
    background-color: rgba(255, 255, 255, 0.8);
}

.LoadingSpinner_spinner__\\+wPdQ {
    border: 4px solid rgba(0, 0, 0, 0.1); 
    border-left: 4px solid #007bff; 
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: LoadingSpinner_spin__XnuOF 1s linear infinite;
}

@keyframes LoadingSpinner_spin__XnuOF {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}`, "",{"version":3,"sources":["webpack://./src/components/LoadingSpinner/LoadingSpinner.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ,eAAe;IACf,0CAA0C;AAC9C;;AAEA;IACI,oCAAoC;IACpC,8BAA8B;IAC9B,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,wDAAkC;AACtC;;AAEA;IACI,KAAK,uBAAuB,EAAE;IAC9B,OAAO,yBAAyB,EAAE;AACtC","sourcesContent":[".loading-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh; \n    width: 100vw; \n    position: fixed;\n    background-color: rgba(255, 255, 255, 0.8);\n}\n\n.spinner {\n    border: 4px solid rgba(0, 0, 0, 0.1); \n    border-left: 4px solid #007bff; \n    border-radius: 50%;\n    width: 100px;\n    height: 100px;\n    animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n    0% { transform: rotate(0deg); }\n    100% { transform: rotate(360deg); }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading-container": `LoadingSpinner_loading-container__clMX+`,
	"spinner": `LoadingSpinner_spinner__+wPdQ`,
	"spin": `LoadingSpinner_spin__XnuOF`
};
export default ___CSS_LOADER_EXPORT___;
