import { date } from '../utils/helpers/format'

export class Batches {
    static fromJsonToObject(jsonBatches) {
        return jsonBatches.map(jsonBatch => _Batch.fromJson(jsonBatch))
    }
}

class _Batch {
    static fromJson(params) {
        return {
            id: params.id,
            batch_name: params.name,        
            username: params.username, 
            uploaded_at: date(params.uploaded_at) 
        }
    }
}
