import { CompanyColors } from "../utils/enums/company_state"

export class Companies {
    static fromJsonToObject(jsonCompanies) {
        return jsonCompanies.map(jsonCompany => _Company.fromJson(jsonCompany))
    }

    static getAllCompaniesForListBox(companies) {
        return companies.map(company => [
            company.legal_company_name, 
            company.id,
            Companies.#getCompanyColorBasedOnFeedback(company.has_feedback)
        ])
    }

    static #getCompanyColorBasedOnFeedback(
        hasCompanyFeedback, 
        colorWhenNoFeedback = CompanyColors.WHITE, 
        colorWhenHasFeedback = CompanyColors.GREEN
    )  {    
        return hasCompanyFeedback ? colorWhenHasFeedback : colorWhenNoFeedback
    }
}

class _Company {
    static fromJson(params) {
        return {
            batch_id: params.batch_id,
            has_feedback: !(params.feedback === null),        
            id: params.id, 
            legal_company_name: params.legal_company_name 
        }
    }
}