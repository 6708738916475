import { StringFormatter } from '../../utils/helpers/stringFormatter'

export class CompanyLocations {
    static toGridFormat(payload, width = 220) {
        let rows = payload["locations"]
        rows = rows.map((row, index) => ({
            id: index+1, 
            ...row
        }))

        const row = rows[0]
        let columns = Object.keys(row)
        
        columns = columns.map(column => ({ 
            field: column,
            headerName: StringFormatter.toGridFormat(column),
            width: width,
        }))
        
        return [rows, columns]
    }

    static getCompanyName(payload) {
        return payload["company"]
    }
}