import styles from './ListRow.module.css';

export const ListRow = ({ components, data }) => {
    return (
        <div className={styles["top-flex-container-down"]}>
            {
                data[0] !== null ?
                (
                    <div className={styles["top-flex-item-down"]}>
                    <div className={styles["map-container"]}>
                        {components[0]}
                    </div>
                </div>
                ) : (
                    <div className={styles["empty"]}></div>
                )
            }
            {
                data[1] !== null ? 
                (
                    <div className={styles["top-flex-item-down"]}>
                        {components[1]}
                    </div>
                ) : (
                    <div className={styles["empty"]}></div>
                )
            }
            <div className={styles["item-padding"]}> </div>
        </div>
    )        
}