import axios from 'axios'
import { parseApiError } from '../utils/parsers/parseApiError';

export const fetchCompanyLocations = async (companyIDPathParam) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_CLOUD_IB_BASE_URL_ANALYTICS_FAST_API}/${process.env.REACT_APP_CLOUD_IB_ENDPOINT_GET_LOCATIONS}/${companyIDPathParam}`
        )
        return response.data
    } catch (error) {
        return parseApiError(error)
    }
}