import axios from 'axios';
import { parseApiError } from '../utils/parsers/parseApiError';
import { Token } from '../utils/authentication/auth';

export const insertFeedback = async (authContext, requestData) => {
    let { payload, headers } = requestData

    const jwt = await Token.refreshAccessTokenIfAuthenticated(authContext)

    const accessToken = jwt || authContext.jwt
    headers = {
        ...headers ?? {},  
        ...(accessToken && { 'Authorization': `Bearer ${accessToken}` }), 
    }

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_CLOUD_IB_BASE_URL_ANALYTICS_FAST_API}/${process.env.REACT_APP_CLOUD_IB_ENDPOINT_FEEDBACK}`,
            payload,
            {
                headers: headers
            }
        )

        if (response.status === 201) {
            return {
                success: true,
                data: response.data
            }
        } else {
            return {
                success: false,
                error: `Unexpected response status: ${response.status}. Expected 201 Created.`
            }
        }
    } catch (error) {
        return parseApiError(error)
    }
}

export const updateFeedback = async (authContext, requestData) => {
    let { payload, headers } = requestData

    const jwt = await Token.refreshAccessTokenIfAuthenticated(authContext)

    const accessToken = jwt || authContext.jwt
    headers = {
        ...headers ?? {},  
        ...(accessToken && { 'Authorization': `Bearer ${accessToken}` }), 
    }

    try {
        const response = await axios.put(
            `${process.env.REACT_APP_CLOUD_IB_BASE_URL_ANALYTICS_FAST_API}/${process.env.REACT_APP_CLOUD_IB_ENDPOINT_FEEDBACK}`,
            payload,
            {
                headers: headers
            }
        )

        if (response.status === 200) {
            return {
                success: true,
                data: response.data
            }
        } else {
            return {
                success: false,
                error: `Unexpected response status: ${response.status}. Expected 201 Created.`
            }
        }
    } catch (error) {
        return parseApiError(error)
    }
}

export const fetchAnalyticsCSV = async (authContext, requestData, pathParams) => {
    let { headers } = requestData

    const jwt = await Token.refreshAccessTokenIfAuthenticated(authContext)

    const accessToken = jwt || authContext.jwt
    headers = {
        ...headers ?? {},  
        ...(accessToken && { 'Authorization': `Bearer ${accessToken}` }), 
    }

    const endpoint = `${process.env.REACT_APP_CLOUD_IB_ENDPOINT_GET_FEEDBACKS_CSV_FAST_API}/${Object.values(pathParams).join("/")}`
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_CLOUD_IB_BASE_URL_ANALYTICS_FAST_API}/${endpoint}`,
            {
                headers: headers,
            }
        )
        return {
            success: true,
            data: response.data
        }
    } catch (error) {
        return parseApiError(error)
    }
}