export const defaultScreenCenter = [54.5260, 15.2551]
export const defaultZoom = 4
export const topHomePagePaddings = ["3%", "40%", "8%"]
export const topCompanyLocationsPagePaddings = ["3%", "50%", "8%"]
export const topDownHomePagePaddings = ["6.8%", "20%", "2.5%"]
export const fileTypes = ["text/plain", "text/csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]

export const additionalCompanyLocationsConfig = {
    timeout: 120000,
    headers: {
        "Content-Type": "application/json",
    }
}

export const databaseSubmitLabel = "Submit To Database"

export const companyLocationsFilteredFieldNames = [
    "Location Name", 
    "Full Address",
    "Country",
    // "Continent", 
    "Source 1 link"
]

export const NO_CSV_ERROR_MESSAGE = ""
