export const handleCSVDownload = (blob, fileName) => {
    try {
        if (!blob) {
            return "No blob data provided"
        }

        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", fileName)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)

    } catch (error) {
        return `Error during CSV download: ${error}`
    }
}
