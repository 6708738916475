import styles from './Header.module.css';

export const Header = ({rows, params, options}) => {
    return (
        <div className={styles[options.container]}>
                {rows.map((row, rowIndex) => (
                    <div key={rowIndex} className={styles[options.row]}>
                        {row.map((item, colIndex) => (
                            <div key={colIndex} className={styles[options.item]} style={{ paddingLeft: item.padding }}>
                                {item.component && item.component(...(params[rowIndex][colIndex] || []))}
                            </div>
                        ))}
                    </div>
                ))}
    </div>
    ) 
}