import axios from 'axios'
import { parseApiError } from '../utils/parsers/parseApiError';
import { Token } from '../utils/authentication/auth';

export const fetchCompanyLocations = async (authContext, companyIDPathParam) => {
    const jwt = await Token.refreshAccessTokenIfAuthenticated(authContext)

    const accessToken = jwt || authContext.jwt
    const headers = accessToken 
    ? { 'Authorization': `Bearer ${accessToken}` } 
    : {}

    try {
        const response = await axios.get(
            `${process.env.REACT_APP_CLOUD_IB_BASE_URL_ANALYTICS_FAST_API}/${process.env.REACT_APP_CLOUD_IB_ENDPOINT_GET_LOCATIONS}/${companyIDPathParam}`,
            {
                headers: headers
            }
            
        )
        return response.data
    } catch (error) {
        return parseApiError(error)
    }
}