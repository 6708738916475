export const correctUrlOptions = [
    { value: "option1", label: "Correct" },
    { value: "option2", label: "Incorrect sub-website" },
    { value: "option3", label: "Incorrect base URL" },
    { value: "option4", label: "Wrong company" },
    { value: "option5", label: "Other" },
]

export const correctLocationsOptions = [
    { value: "option1", label: "Correct" },
    { value: "option2", label: "Geography missing" },
    { value: "option3", label: "Regulator address captured"},
    { value: "option4", label: "Count seems incorrect" },
    { value: "option5", label: "Other" },
]

