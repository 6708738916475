export const companyLocationsScrapedMock = {
    "company": "atoss_software",
    "locations": [
        {
            "city": "Munich",
            "country": "Germany",
            "office_name": "ATOSS Software SE",
            "street": "Rosenheimer Straße 141 h",
            "zip_code": "81671"
        },
        {
            "city": "Berlin",
            "country": "Germany",
            "office_name": "ATOSS Software SE",
            "street": "Knesebeckstrasse 62/63",
            "zip_code": "10719"
        },
        {
            "city": "Brussels",
            "country": "Belgien",
            "office_name": "ATOSS Software SE",
            "street": "Rue aux Laines 70",
            "zip_code": "1000"
        },
        {
            "city": "Cham",
            "country": "Germany",
            "office_name": "ATOSS CSD Software GmbH",
            "street": "Rodinger Straße 19",
            "zip_code": "93413"
        },
        {
            "city": "Meerbusch",
            "country": "Germany",
            "office_name": "ATOSS Software SE",
            "street": "Robert-Bosch-Straße 14",
            "zip_code": "40668"
        },
        {
            "city": "Frankfurt/Main",
            "country": "Germany",
            "office_name": "ATOSS Software SE",
            "street": "Herriotstraße 8",
            "zip_code": "60528"
        },
        {
            "city": "Hamburg",
            "country": "Germany",
            "office_name": "ATOSS Software SE",
            "street": "Osterbekstraße 90b",
            "zip_code": "22083"
        },
        {
            "city": "Mettingen",
            "country": "Germany",
            "office_name": "ATOSS Software SE",
            "street": "Westerkappelner Straße 26 – 1. OG",
            "zip_code": "49497"
        },
        {
            "city": "Paris",
            "country": "France",
            "office_name": "ATOSS Software SE",
            "street": "40 Rue du Louvre",
            "zip_code": "75001"
        },
        {
            "city": "Sibiu",
            "country": "Romania",
            "office_name": "SC ATOSS Software SRL Business Center Sibiu",
            "street": "Nicolaus Olahus street, no. 5 Building B, ground floor",
            "zip_code": "550370"
        },
        {
            "city": "Stockholm",
            "country": "Sweden",
            "office_name": "ATOSS Software SE",
            "street": "Vasagatan 7",
            "zip_code": "111 20"
        },
        {
            "city": "Leinfelden-Echterdingen / Stuttgart",
            "country": "Germany",
            "office_name": "ATOSS Software SE",
            "street": "Bertha-Benz Platz 1",
            "zip_code": "70771"
        },
        {
            "city": "Timisoara",
            "country": "Romania",
            "office_name": "SC ATOSS Software SRL",
            "street": "Calea Torontalului 69",
            "zip_code": "300668"
        },
        {
            "city": "Utrecht",
            "country": "Netherlands",
            "office_name": "ATOSS Software SE",
            "street": "Varrolaan 100",
            "zip_code": "3584 BH"
        },
        {
            "city": "Vienna",
            "country": "Austria",
            "office_name": "ATOSS Software Ges.m.b.H.",
            "street": "Ungargasse 64-66, Stiege 3, Top 503",
            "zip_code": "1030"
        },
        {
            "city": "Zurich",
            "country": "Switzerland",
            "office_name": "ATOSS Software AG",
            "street": "Schärenmoosstr. 77",
            "zip_code": "8052"
        },
        {
            "city": "Munich",
            "country": "Germany",
            "office_name": "ATOSS Aloud GmbH",
            "street": "Rosenheimer Straße 141 h",
            "zip_code": "81671"
        }
    ]
}