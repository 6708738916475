import { CompanyColors } from "./enums/company_state";
import { Dimensions } from "./enums/dimensions";
import { NoSuchDimensionError } from "./errors";

export class DataTranformer {
    static toCompaniesData(companiesData, ...columns) {
        const companiesFilteredFields = companiesData.map(companyData =>
            columns.map(column => companyData[column])
        );
        const companyNameList = companiesFilteredFields.map(companyName => companyName[1])
        return [companiesFilteredFields, companyNameList]
    }

    static getColumns(iterator, ...columns) {
        return iterator.map(item =>
            columns.map(column => item[column])
        )
    }

    static toDataGridListBoxPresenter(data, fields, keepLastFieldAsItIs = true) {
        if(data.length === 0) {
            return null
        }

        if(data[0].length !== fields.length) {
            return []
        }

        return data.map(row => 
            row.map((item, index) => 
                index === row.length - 1 && keepLastFieldAsItIs 
                    ? item   
                    : `${fields[index]}: ${item}`
            )
        )
    }

    static toColorCodedCompanies(companiesA, companiesB, color, defaultColor = CompanyColors.WHITE, companyADimension = Dimensions.ONE_D) {
        switch (companyADimension) {
            case Dimensions.ONE_D:
                return companiesA.map(companyA => [
                    companyA,
                    companiesB.includes(companyA) ? color : defaultColor
                ])
            case Dimensions.TWO_D:
                return companiesA.map(company => {
                    const [companyName, companyColor] = company
                    return companiesB.includes(companyName)
                        ? [companyName, color]
                        : [companyName, companyColor]
                })
            default:
                throw new NoSuchDimensionError(
                    "The tensor does not have the specified dimension. Ensure the tensor is properly formatted and has the required dimensions."
                )
        }
    }
}