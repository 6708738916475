import { correctUrlOptionsConvertor, correctLocationsOptionsConvertor } from "../comboBoxOptions/convertors/fromKeyToValue"

export const handleSpecificInputChange = (id, value, ...callbacks) => {
    switch (id) {
        case "comment-url-combo-box":
            const isUrlInputVisible = correctUrlOptionsConvertor[value] !== correctUrlOptionsConvertor["option1"]
            callbacks[0](isUrlInputVisible)
            break
        case "comment-locations-combo-box":
            const isLocationsIputVisible = correctLocationsOptionsConvertor[value] !== correctLocationsOptionsConvertor["option1"]
            callbacks[1](isLocationsIputVisible)
            break
        default:
            break
    }
}