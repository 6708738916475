import { createSelector } from 'reselect';

const selectSession = (state) => state.session;

export const selectUsername = createSelector(
    [selectSession],
    (session) => session.username
)

export const selectBatchID = createSelector(
    [selectSession],
    (session) => session.batchID
)

export const selectUserID = createSelector(
    [selectSession],
    (session) => session.userID
)

export const selectFileName = createSelector(
    [selectSession],
    (session) => session.fileName
)

export const selectFileLoader = createSelector(
    [selectSession],
    (session) => session.fileLoader
)

export const selectFileContent = createSelector(
    [selectSession],
    (session) => session.fileContent
)

export const selectInsertBatch = createSelector(
    [selectSession],
    (session) => session.insertBatch
)