import {
    USERNAME,
    BATCH_ID,
    USER_ID,
    FILE_NAME,
    FILE_LOADER,
    FILE_CONTENT,
    INSERT_BATCH
} from './sessionActionTypes';

const initialState = {
    username: "",
    batchID: null,
    userID: null,
    fileName: "",
    fileLoader: null,
    fileContent: "",
    insertBatch: true
}

const actionTypeToStateKeyMap = {
    [USERNAME]: "username",
    [BATCH_ID]: "batchID",
    [USER_ID]: "userID",
    [FILE_NAME]: "fileName",
    [FILE_LOADER]: "fileLoader",
    [FILE_CONTENT]: "fileContent",
    [INSERT_BATCH]: "insertBatch"
}

export const sessionReducer = (state = initialState, action) => {
    const { type, payload } = action

    const stateKey = actionTypeToStateKeyMap[type]

    if (stateKey) {
        return {
            ...state,
            [stateKey]: payload.value
        }
    }

    return state
}