// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FancyPasswordInput_passwordInput__tsNCY {
    width: 100%;
    padding: 14px 20px;
    margin: 10px 0;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 30px;
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.FancyPasswordInput_passwordInput__tsNCY:focus {
    border-color: #9c9b9b;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    outline: none;
}

.FancyPasswordInput_passwordInput__tsNCY::placeholder {
    color: #ccc;
}

.FancyPasswordInput_passwordInput__tsNCY:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/components/FancyPasswordInput/FancyPasswordInput.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,cAAc;IACd,sBAAsB;IACtB,sBAAsB;IACtB,mBAAmB;IACnB,eAAe;IACf,iCAAiC;IACjC,wCAAwC;IACxC,wDAAwD;AAC5D;;AAEA;IACI,qBAAqB;IACrB,yCAAyC;IACzC,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;AACvB","sourcesContent":[".passwordInput {\n    width: 100%;\n    padding: 14px 20px;\n    margin: 10px 0;\n    box-sizing: border-box;\n    border: 2px solid #ccc;\n    border-radius: 30px;\n    font-size: 1rem;\n    font-family: 'Roboto', sans-serif;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    transition: border-color 0.3s ease, box-shadow 0.3s ease;\n}\n\n.passwordInput:focus {\n    border-color: #9c9b9b;\n    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);\n    outline: none;\n}\n\n.passwordInput::placeholder {\n    color: #ccc;\n}\n\n.passwordInput:disabled {\n    background-color: #f5f5f5;\n    cursor: not-allowed;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"passwordInput": `FancyPasswordInput_passwordInput__tsNCY`
};
export default ___CSS_LOADER_EXPORT___;
