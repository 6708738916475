import React, { useState, useEffect } from 'react';
import styles from './LoadDataPage.module.css'
import { useDispatch } from 'react-redux';
import { FilePicker } from 'evergreen-ui';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { onFilePickerChangeHelper } from '../../utils/helpers/callbacks';
import { IgrMultiColumnComboBoxModule, IgrMultiColumnComboBox } from 'igniteui-react-grids';
import { fetchAllBatchesInWork } from '../../services/batches';
import { Batches } from '../../models/batches';
import { FileLoad } from '../../utils/enums/files';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';
import { sessionActions } from '../../state/session/sessionActions'


export const LoadDataPage = () => {
    IgrMultiColumnComboBoxModule.register()

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { username, id } = location.state

    const [base64FileContent, setBase64FileContent] = useState(null)
    const [fileName, setFileName] = useState("")
    const [batches, setBatches] = useState([]) 
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const fetchBatchesInWork = async (params) => {
            let batchesSerialized = []
            const { setterBatches, setterIsLoading } = params

            try {
                const jsonBatches = await fetchAllBatchesInWork()
                batchesSerialized = Batches.fromJsonToObject(jsonBatches)
                setterBatches(batchesSerialized)
            } catch(error) {
                console.error(`Error: ${error}`)
                toast.error(`Error: Could not fetch the batches`)
            }
            finally {
                setterIsLoading(false)
            }
        }

        fetchBatchesInWork(
            {
                setterBatches: setBatches,
                setterIsLoading: setIsLoading
            }
        )
    }, [])

    useEffect(() => {
        if (fileName && base64FileContent !== null) {
            navigateWithFileData(
                navigate, 
                "/home_page",
                dispatch,
                {
                    results: {
                        username: username,
                        batchID: null,
                        userID: id,
                        fileName: fileName, 
                        fileLoader: FileLoad.FILE_PICKER, 
                        fileContent: base64FileContent    
                    },
                    actions: {
                        ...sessionActions
                    }
                }
            )
        }
    }, [fileName, base64FileContent, username, id, navigate, dispatch])

    const onFileChange = async (files) => {
        const filePickerResult = await onFilePickerChangeHelper(
            files, 
            {
                setFileName: setFileName, 
                setFileContent: setBase64FileContent
            }
        )

        if (!filePickerResult.success) {
            toast.error(filePickerResult.message)
            return
        }
    }

    const navigateWithFileData  = (navigateObject, navigateRoute, dispatchObject, params) => {
        const results = params?.results
        const actions = params?.actions

        if (results?.fileContent === null) {
            toast.error("Please select a file before proceeding.")
            return
        }
        if (typeof results?.fileContent !== "string") {
            toast.error(`File is not in a correct format`)
            return
        }

        const resultValues = Object.values(results)
        const actionValues = Object.values(actions)

        for(let i = 0; i < resultValues.length; ++i) {
            dispatchObject(actionValues[i](resultValues[i]))
        }
        
        navigateObject(navigateRoute)
    }

    const onSelectedValueChanged = async (s, e) => {
        const selectedBatchID = e._implementation.b
        const selectedBatchRow = batches.find(batch => selectedBatchID === batch.id)

        if(!selectedBatchRow) {
            toast.error("Row selection failed: could not select that given row.")
            return
        }

        navigateWithFileData(
            navigate, 
            "/home_page",
            dispatch,
            {
                results: {
                    username: username,
                    batchID: selectedBatchRow.id,
                    userID: id,
                    fileName: selectedBatchRow.batch_name, 
                    fileLoader: FileLoad.COMBO_BOX, 
                    fileContent: "" 
                },
                actions: {
                    ...sessionActions
                }
            }
        )
    }

    if(isLoading) {
        return <LoadingSpinner />
    }    

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",
            backgroundColor: "#f0f4f8",
            padding: 20,
            boxSizing: "border-box"
        }}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 40,
                backgroundColor: "#fff",
                borderRadius: 10,
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)"
            }}>
                <div className={styles["title"]}>
                    <h1>Load Batch For Processing</h1>
                </div>
                <div> 
                    <div>
                    <h2 className={styles["primary-text"]}>
                        Upload Your File
                    </h2>
                        <FilePicker
                            width={600}
                            height={50}
                            placeholder="Select a batch to upload"
                            style={{ marginBottom: 30 }}
                            accept=".csv"
                            onChange={onFileChange}
                        />
                    </div>
                    <div className={styles["horizontal-padding"]}>
                        <h2 className={styles["primary-text"]}>
                            Select Your Working Batch
                        </h2>
                        <IgrMultiColumnComboBox 
                            width="600px" 
                            height="50px" 
                            defaultColumnWidth={150}
                            placeholder="Select a previously loaded batch"
                            dataSource={batches}
                            selectedValueChanged={onSelectedValueChanged}
                        />
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}
