import axios from "axios"
import { parseApiError } from '../utils/parsers/parseApiError';

export const refreshAccessToken = async () => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_CLOUD_IB_BASE_URL_ANALYTICS_FAST_API}/${process.env.REACT_APP_CLOUD_IB_ENDPOINT_POST_REFRESH_ACCESS_TOKEN}`,
            {},
            { withCredentials: true }
        )
        return {
            success: true,
            minutesValid: response.data.minutes_valid,
            accessToken: response.data.access_token
        }
            
    } catch(error) {
        return parseApiError(error)
    }
}
