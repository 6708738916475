import { configureStore } from '@reduxjs/toolkit';
import { rootReducer }  from './rootReducer';
import { createSerializableStateInvariantMiddleware } from '@reduxjs/toolkit';

const serializableMiddleware = createSerializableStateInvariantMiddleware({
  isSerializable: () => true, 
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(serializableMiddleware), 
})
