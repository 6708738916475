// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MapBox_map-container__41Pvg {
    display: flex;
    align-items: flex-start;
    height: 35vh;
    width: 80vw;
    max-width: 100%;
    max-height: 50vh;
    box-sizing: border-box;
    margin: 0 auto;
}

@media (max-width: 1200px) {
    .MapBox_map-container__41Pvg {
        height: 35vh;
        width: 85vw;
    }
}

@media (max-width: 992px) {
    .MapBox_map-container__41Pvg {
        height: 30vh;
        width: 90vw;
    }
}

@media (max-width: 768px) {
    .MapBox_map-container__41Pvg {
        height: 35vh;
        width: 95vw;
    }
}

@media (max-width: 480px) {
    .MapBox_map-container__41Pvg {
        height: 35vh;
        width: 100vw;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/HomePage/MapBox/MapBox.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,sBAAsB;IACtB,cAAc;AAClB;;AAEA;IACI;QACI,YAAY;QACZ,WAAW;IACf;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,WAAW;IACf;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,WAAW;IACf;AACJ;;AAEA;IACI;QACI,YAAY;QACZ,YAAY;IAChB;AACJ","sourcesContent":[".map-container {\n    display: flex;\n    align-items: flex-start;\n    height: 35vh;\n    width: 80vw;\n    max-width: 100%;\n    max-height: 50vh;\n    box-sizing: border-box;\n    margin: 0 auto;\n}\n\n@media (max-width: 1200px) {\n    .map-container {\n        height: 35vh;\n        width: 85vw;\n    }\n}\n\n@media (max-width: 992px) {\n    .map-container {\n        height: 30vh;\n        width: 90vw;\n    }\n}\n\n@media (max-width: 768px) {\n    .map-container {\n        height: 35vh;\n        width: 95vw;\n    }\n}\n\n@media (max-width: 480px) {\n    .map-container {\n        height: 35vh;\n        width: 100vw;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"map-container": `MapBox_map-container__41Pvg`
};
export default ___CSS_LOADER_EXPORT___;
