import React from 'react';
import styles from './FancyPasswordInput.module.css';

export const FancyPasswordInput = ({ value, onChange, placeholder = "Password" }) => {
    return (
        <input
            type="password"
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            className={styles["passwordInput"]}
        />
    );
};
