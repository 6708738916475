import styled from 'styled-components';

const InputContainer = styled.div`
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 10px;
    width: 100%;
`;

const StyledInput = styled.input`
    width: ${props => props.width || '100%'};
    height: ${props => props.height || 'auto'};
    max-width: 100%;
    padding: 20px;
    border: 2px solid #ccc;
    border-radius: ${props => {
        switch (props.shape) {
            case 'rounded':
                return '12px';
            case 'circle':
                return '50px';
            case 'pill':
                return '50px';
            default:
                return '4px';
        }
    }};
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    display: ${props => (props.isVisible ? 'block' : 'none')};
    
    &:focus {
        border-color: #9c9b9b;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        outline: none;
    }

    &:disabled {
        background-color: #f5f5f5;
        cursor: not-allowed;
    }
`;

export const FancyTextInput = ({
    placeholder,
    value,
    onChange,
    width,
    height,
    isEnabled,
    onClick,
    isVisible = true,
    id = "id",
    shape = "rounded",
}) => {
    return (
        <InputContainer>
            <StyledInput
                id={id}
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                width={width}
                height={height}
                shape={shape}
                isVisible={isVisible}
                disabled={!isEnabled}
                onClick={onClick}
            />
        </InputContainer>
    );
};

