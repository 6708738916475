import { NoSuchDataFormatAvaiableError, NoSuchFileAvailableError } from '../errors';
import { NO_CSV_ERROR_MESSAGE } from '../constants'
import { DataConvertor } from '../../utils/helpers/convertors'

export class Reader {
    #file
    #content = ""
    #validationContent = ""

    constructor(file) {
        this.#file = file
    }

    read() {
        return new Promise((resolve, reject) => {
            if (!this.#file) {
                return reject(new NoSuchFileAvailableError("No file provided."))
            }

            const extension = this.#file.name.split('.').pop().toLowerCase()
            if (extension !== 'csv') {
                return reject(new NoSuchDataFormatAvaiableError("Only .csv files are supported."))
            }

            const reader = new FileReader()

            reader.readAsText(this.#file)

            reader.onload = (event) => {
                const data = event.target.result
                this.#content = DataConvertor.stringToBase64(data)
                this.#validationContent = data
                resolve(this)
            }

            reader.onerror = (error) => {
                reject(error)
            }

        })
    }

    validate() {
        if (!this.#validationContent) {
            return "The content is empty. Please provide a file with valid content."
        }

        const lines = this.#validationContent.trim().split('\n');
        if (lines.length === 0 || lines.every(line => line.trim() === '')) {
            return "The content is empty. Please provide a file with valid content."
        }

        return NO_CSV_ERROR_MESSAGE
    }

    toString() {
        return this.#content
    }

    build() {
        const validationMessage = this.validate()

        if (validationMessage.startsWith("The content is empty") || validationMessage.includes("valid content")) {
            return validationMessage
        }
        
        return this.toString()
    }
}

