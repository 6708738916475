import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { HomePage } from './pages/HomePage/HomePage'
import { LoadDataPage } from './pages/LoadDataPage/LoadDataPage'
import { PopupPage } from './pages/PopupPage/PopupPage'
import { ToastContainer } from 'react-toastify'

export const App = () => {
  return (
    <Router>
      <ToastContainer/>
      <Routes>
        <Route path="/" element={<PopupPage />}></Route>
        <Route path="/data_selection" element={<LoadDataPage />} />
        <Route path="/home_page" element={<HomePage />}></Route>
      </Routes>
    </Router>
  )
}


