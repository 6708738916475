import 'react-data-grid/lib/styles.css';
import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { LoadingSpinner } from '../../components/LoadingSpinner/LoadingSpinner';

const dataGridStyles = (theme) => ({
    boxShadow: 2,
    border: 2,
    '& .MuiDataGrid-cell:hover': {
        backgroundColor: 'rgba(52, 6, 6, 0.1)',
        cursor: 'pointer',
    },
    '& .MuiDataGrid-virtualScroller': {
        backgroundColor: theme.palette.background.default,
    },
    '& .MuiDataGrid-row.selected': {
        backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-cell.selected': {
        backgroundColor: 'transparent',
    }
})

const getContainerStyles = () => {
    const width = window.innerWidth
    let height

    if (width > 1200) {
        height = '70vh'
    } else if (width > 992) {
        height = '60vh'
    } else if (width > 768) {
        height = '50vh'
    } else {
        height = '36vh'
    }

    return {
        height,
        width: '100%',
        overflow: 'hidden',
        border: '2px solid #000',
        borderRadius: '12px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }
}

const getColumns = (customColumns) => {
    return customColumns.map((col) => ({
        ...col,
        headerClassName: 'header-class', 
        cellClassName: 'cell-class', 
    }))
}

export const CompanyLocationsGrid = ({ columns, rows, config = {} }) => {
    const isLoading = config.isLoading || false

    if (isLoading) {
        return <LoadingSpinner />
    }    

    const containerStyles = getContainerStyles()

    return (
        <div style={containerStyles}>
            <DataGrid
                columns={getColumns(columns)} 
                rows={rows}
                getRowId={(row) => row.id}
                rowHeight={60}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 10,
                        },
                    },
                }}
                pageSizeOptions={[8]}
                checkboxSelection
                disableRowSelectionOnClick
                sx={dataGridStyles({ palette: { background: { default: '#fff' } } })} 
            />
        </div>
    )
}
