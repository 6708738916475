import React from 'react';
import styled from 'styled-components';

const ButtonContainer = styled.button`
    padding: ${props => props.padding};
    padding-left: ${props => props.paddingLeft}; // Add left padding
    font-size: ${props => props.fontSize};
    font-family: ${props => props.fontFamily};
    color: ${props => props.color};
    background-color: ${props => props.bgColor};
    border: ${props => `2px solid ${props.borderColor}`};
    border-radius: ${props => props.borderRadius};
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: ${props => props.boxShadow};
    width: ${props => props.width}; // Add width
    height: ${props => props.height}; // Add height

    &:hover {
        background-color: ${props => props.hoverBgColor};
        border-color: ${props => props.hoverBorderColor};
        box-shadow: ${props => props.hoverBoxShadow};
    }

    &:focus {
        outline: none;
        border-color: ${props => props.focusBorderColor};
        box-shadow: ${props => props.focusBoxShadow};
    }

    &:active {
        background-color: ${props => props.activeBgColor};
        box-shadow: ${props => props.activeBoxShadow};
    }

    @media (max-width: 600px) {
        font-size: ${props => props.mobileFontSize};
        padding: ${props => props.mobilePadding};
    }
`;

export const FancyButton = ({
    onClick,
    children,
    padding = '12px 24px',
    paddingLeft = '0',
    fontSize = '1rem',
    fontFamily = "'Roboto', sans-serif",
    color = '#fff',
    bgColor = '#007bff',
    borderColor = 'transparent',
    borderRadius = '12px',
    boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)',
    hoverBgColor = '#0056b3',
    hoverBorderColor = '#0056b3',
    hoverBoxShadow = '0 6px 12px rgba(0, 0, 0, 0.2)',
    focusBorderColor = '#004080',
    focusBoxShadow = '0 0 0 3px rgba(0, 0, 255, 0.3)',
    activeBgColor = '#003366',
    activeBoxShadow = '0 2px 4px rgba(0, 0, 0, 0.2)',
    mobileFontSize = '0.875rem',
    mobilePadding = '10px 20px',
    width = 'auto',
    height = 'auto'
}) => (
    <ButtonContainer
        onClick={onClick}
        padding={padding}
        paddingLeft={paddingLeft}
        fontSize={fontSize}
        fontFamily={fontFamily}
        color={color}
        bgColor={bgColor}
        borderColor={borderColor}
        borderRadius={borderRadius}
        boxShadow={boxShadow}
        hoverBgColor={hoverBgColor}
        hoverBorderColor={hoverBorderColor}
        hoverBoxShadow={hoverBoxShadow}
        focusBorderColor={focusBorderColor}
        focusBoxShadow={focusBoxShadow}
        activeBgColor={activeBgColor}
        activeBoxShadow={activeBoxShadow}
        mobileFontSize={mobileFontSize}
        mobilePadding={mobilePadding}
        width={width}
        height={height}
    >
        {children}
    </ButtonContainer>
);
