export const correctUrlOptionsConvertor = {
    option1: "Correct",
    option2: "Incorrect sub-website",
    option3: "Incorrect base URL",
    option4: "Wrong company",
    option5: "Other"
}

export const correctLocationsOptionsConvertor = {
    option1: "Correct",
    option2: "Geography missing",
    option3: "Regulator address captured",
    option4: "Count seems incorrect",
    option5: "Other"
}

export const userNameOptionsConvertor = {
    option1: "SRadovic",
    option2: "MSmigic",
    option3: "SMoenninghoff",
    option4: "GSmilyanov",
    option5: "DGlanda"
}