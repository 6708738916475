import axios from 'axios'
import { parseApiError } from '../utils/parsers/parseApiError';

export const fetchAllBatchesInWork = async (...config) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_CLOUD_IB_BASE_URL_ANALYTICS_FAST_API}/${process.env.REACT_APP_CLOUD_IB_ENDPOINT_GET_BATCHES_FOR_COMBO_BOX}`,
            {
                params: config.params,
                timeout: config.timeout,
                headers: config.headers
            }
        )
        return response.data
    } catch (error) {
        return parseApiError(error)
    }
}

export const createBatch = async (config) => {
    const { payload } = config

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_CLOUD_IB_BASE_URL_ANALYTICS_FAST_API}/${process.env.REACT_APP_CLOUD_IB_ENDPOINT_CREATE_BATCH}`,
            payload
        )

        const data = response.data
        
        return {    
            success: true,
            message: data.detail,
            batchID: data.result.id
        }
    } catch (error) {
        return parseApiError(error)
    }
}