import React from 'react';
import { ListBox } from 'primereact/listbox';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import styles from './CompanyListBox.module.css';

export const CompanyListBox = ({ companies, selectedCompany, onChange, isEnabled }) => {
    const options = companies.map(([name, id, color]) => ({
        companyName: name,
        companyID: id,  
        style: { backgroundColor: color.toLowerCase() }
    }))
    
    return (
        isEnabled ? (
            <div className={styles["company-listbox-container"]}>
                <div className={styles["company-listbox"]}>
                    <h1 className={styles["company-listbox-header"]}>Select a Company</h1>
                    <div className={styles["scroll-pane"]}>
                        <ListBox
                            value={selectedCompany}
                            options={options}
                            onChange={(event) => {
                                const selectedRow = event.value
                                const selectedOption = options.find(option => option.companyID === selectedRow.companyID)
                                onChange(selectedOption)
                            }}
                            filter
                            className={styles["p-listbox"]}
                            placeholder="Select a Company"
                            itemTemplate={(option) => (
                                <div className={styles["p-listbox-item"]} style={option.style}>
                                    {option.companyName}
                                </div>
                            )}
                        />
                    </div>
                </div>
            </div>
        ) : <div className={styles["empty"]}></div>
    )
}