import { toast } from 'react-toastify'
import { fileTypes } from '../../utils/constants';
import { Reader } from '../../utils/helpers/reader';

export const onButtonOpenCompanyLocationsPage = ({
    urls, 
    crawlerType,
    iFrameUrl, 
    navigate, 
    location, 
    dispatch, 
    actions, 
    params
}) => {
    if(urls.length === 0) {
        toast.error("Please select a company before moving to the next page.")
        return 
    }

    if(actions.length !== params.length) {
        console.error("Length mismatch: actions and params must be of equal length.")
        toast.error("There is an error with the client-side code. Please check your inputs.")
        return 
    }
    
    for(let i = 0; i < actions.length; ++i) {
        dispatch(actions[i](...params[i]))
    }
    
    const companyUrl = urls[0].at(-1)

    navigate(
        location,
        { state: { 
            url: companyUrl,
            iFrameUrl: iFrameUrl,
            crawlerType: crawlerType
        } 
    })
}

export const onButtonSubmitToDatabaseHelper = async ({
    companyID,
    urlReviewCategory,
    urlReviewComment,
    locationsReviewCategory,
    locationsReviewComment,
    createdBy,
    submitFunction,
    resetFunction,
    setCompanyLocationsInformation,
    defaultCompanyLocationsInformation,
    defaultValue,
    resetArgs
}) => {
    const requestData = {
        payload: {
            id: companyID,
            url_review_category: urlReviewCategory,
            ...(urlReviewComment && { url_review_comment: urlReviewComment }),
            locations_review_category: locationsReviewCategory,
            ...(locationsReviewComment && { locations_review_comment: locationsReviewComment }),
            created_by: createdBy,
        },
        headers: {
            contentType: "application/json"
        }
    }

    try {
        const result = await submitFunction(requestData)
        if (result.success) {
            toast.success("Data submitted successfully!")
        } else {
            toast.error(`Failed to submit data: ${result.error}`)
        }
    } catch (error) {
        toast.error("An unexpected error occurred while submitting data.")
    } finally {
        setCompanyLocationsInformation(defaultCompanyLocationsInformation)
        resetFunction(defaultValue, ...resetArgs)
    }
}

export const onButtonRedirectHelper = async ({
    navigate,
    location,
}) => {
    navigate(location)
}

export const onFilePickerChangeHelper = async (files, { setFileName, setFileContent }) => {
    if (files.length === 0) {
        setFileContent(null)
        return { success: false, message: "No file selected." }
    }

    const file = files[0]
    const fileType = file.type
    const fileName = file.name

    if (!fileTypes.includes(fileType)) {
        setFileContent(null)
        return { success: false, message: "Invalid file type. Please select a .csv file." }
    }

    try {
        const reader = new Reader(file)
        const content = await reader.read().then(() => reader.build())
        setFileContent(content)
        setFileName(fileName)
        return { success: true, message: "File content successfully loaded." }
    } catch (error) {
        setFileContent(null)
        console.error("File reading error:", error)
        return { success: false, message: `Error reading file: ${error.message}` }
    }
}
