import React from 'react';
import styled from 'styled-components';

const IframeContainer = styled.div`
  position: relative;
  width: ${({ width }) => width};
  padding-top: ${({ height }) => height};
  overflow: hidden;
  max-width: 100%;
  max-height: 50vh;
  margin: 0 auto;
  box-sizing: border-box;

  @media (max-width: 1200px) {
    width: ${({ width }) => width === '100%' ? '85vw' : width};
  }

  @media (max-width: 992px) {
    width: ${({ width }) => width === '100%' ? '90vw' : width};
    height: ${({ height }) => height === '56.25%' ? '30vh' : height};
  }

  @media (max-width: 768px) {
    width: ${({ width }) => width === '100%' ? '95vw' : width};
    height: ${({ height }) => height === '56.25%' ? '35vh' : height};
  }

  @media (max-width: 480px) {
    width: ${({ width }) => width === '100%' ? '100vw' : width};
    height: ${({ height }) => height === '56.25%' ? '35vh' : height};
  }
`;

const StyledIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

export const IFrameBox = ({ src, title, width = "100%", height = "56.25%" }) => {
  return (
    <IframeContainer width={width} height={height}>
      <StyledIframe src={src} title={title} />
    </IframeContainer>
  );
};
