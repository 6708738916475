import { FileLoad } from '../../../utils/enums/files'
import { fetchAllCompanies } from '../../../services/companies'
import { toast } from 'react-toastify'
import { Companies } from '../../../models/companies'
import { createBatch } from '../../../services/batches'
import { Locations } from '../../../models/locations'
import { fetchCompanyLocations } from '../../../services/locations'
import { fetchAnalyticsCSV } from '../../../services/analytics'
import { handleCSVDownload } from '../../../utils/helpers/handleCSVDownload'

const fetchAndSetCompanies = async (fetchParams) => {
    const { setterIsLoading, setterCompanies, batchID } = fetchParams 

    const companiesResponse = await fetchAllCompanies(batchID)
    if(!companiesResponse.success) {
        console.error(`Error in ${loadFromFilePicker.name}:`, companiesResponse)
        toast.error("Failed to fetch companies")
        setterIsLoading(false)
        return
    }

    const companiesSerialized = Companies.fromJsonToObject(companiesResponse.companies)
    setterCompanies(companiesSerialized)
    setterIsLoading(false)
}

const loadFromFilePicker = async (filePickerParams) => {
    const { 
        setterIsLoading, 
        setterCompanies, 
        createBatchParams, 
        setNewBatchID, 
        getNewBatchID, 
        insertBatchRef,
        disableBatchInsert,
        dispatch,
        setBatchID,
        setInsertBatch,
        canInsertBatch
    } = filePickerParams
    
    if(insertBatchRef.current) {
        const batchResponse = await createBatch(createBatchParams)
        if(!batchResponse.success) {
            console.error(`Error in method ${loadFromFilePicker.name} : ${createBatchParams.errorMessage}`)
            toast.error("Failed to create batch")
            setterIsLoading(false)
            return
        }

        // Update the batch ID with the value received from the API response
        disableBatchInsert()
        setNewBatchID(batchResponse.batchID)

        dispatch(setBatchID(batchResponse.batchID))
        dispatch(setInsertBatch(canInsertBatch))
    }

    const params = {
        setterIsLoading: setterIsLoading,
        setterCompanies: setterCompanies,
        batchID: getNewBatchID(),
    }
    await fetchAndSetCompanies(params)
}

const loadFromComboBox = async (comboBoxParams) => {
    const { setterIsLoading, setterCompanies, batchID } = comboBoxParams

    const params = {
        setterIsLoading: setterIsLoading,
        setterCompanies: setterCompanies,
        batchID: batchID
    }
    await fetchAndSetCompanies(params)
}

export const handleFileLoad = async (fileLoader, params) => {
    const fileLoaders = {
        [FileLoad.FILE_PICKER]: loadFromFilePicker,
        [FileLoad.COMBO_BOX]: loadFromComboBox,
    }

    const loaderFunction = fileLoaders[fileLoader]

    if (loaderFunction) {
        await loaderFunction(params)
    } else {
        console.error(`No such file loader, actual file loader is: ${fileLoader}`)
        toast.error("Error: No such file loader.")
    }
}

export const onSelectedCompanyHelper = async (company, setters) => {
    try {
        if (!company) {
            toast.error("Company does not exist.")
            return
        }

        const companyLocations = await fetchCompanyLocations(company.companyID)
        const locations = Locations.fromJsonToObject(companyLocations.locations)

        const mapCoordinates = Locations.getLocationsCoordinates(locations)
        setters.setMapCoordinates(mapCoordinates)

        const companyLocationsInformation = Locations.getLocationsInformation(locations)
        setters.setCompanyLocationsInformation(companyLocationsInformation)

        setters.setIFrameUrl(
            `${process.env.REACT_APP_CLOUD_IB_BASE_URL_ANALYTICS_FAST_API}/` +
            `${process.env.REACT_APP_CLOUD_IB_ENDPOINT_GET_PROXY_IFRAME}?url=${locations.at(-1).source_link}`
        )
        
        setters.setCompanyID(company.companyID)
        setters.setCompanyInputValue(company.companyName)
        setters.setSelectedCompany(company)
        setters.handleCompanyListBox(setters.setIsCompanyListBoxEnabled)
   
    } catch (error) {
        console.error(`Error: ${error}`)
        toast.error("An error occurred while fetching the locations of a company.")
    }
}

export const onButtonDownloadAnalyticsCSVHelper = async (_, params) => {
    if (params.userID === null || typeof params.userID !== "number") {
        toast.error("Could not download CSV successfully because the user was not provided")
        return
    }

    if(params.batchID === null || typeof params.batchID !== "number") {
        toast.error("Could not download CSV successfully because the batch was not provided")
        return
    }

    const requestData = {
        headers: {
            contentType: "text/csv"
        }
    }

    try {
        const blob_or_error = await fetchAnalyticsCSV(
            requestData, 
            { 
                userID: params.userID, 
                batchID: params.batchID 
            }
        )

        if(!blob_or_error.success) {
            console.error(`Blob error: ${blob_or_error.error}`)
            toast.error("An error occurred while processing the request. The server encountered an issue. Please try again later or contact support if the issue persists.")
            return
        }

        const fileNameForDownload = "analysis.csv"
        const isCSVDownloaded = handleCSVDownload(blob_or_error.data, fileNameForDownload)

        if (typeof isCSVDownloaded === "undefined") {
            toast.success("Downloaded CSV successfully, please check your Downloads folder")
        } else {
            toast.error("Failed to download CSV.")
        }
    } catch (error) {
        toast.error("An unexpected error occurred while submitting data.")
    }
}