import {
    USERNAME,
    BATCH_ID,
    USER_ID,
    FILE_NAME,
    FILE_LOADER,
    FILE_CONTENT,
    INSERT_BATCH
} from './sessionActionTypes';

const setUsername = (value) => ({
    type: USERNAME,
    payload: { value },
})

export const setBatchID = (value) => ({
    type: BATCH_ID,
    payload: { value },
})

const setUserID = (value) => ({
    type: USER_ID,
    payload: { value },
})

const setFileName = (value) => ({
    type: FILE_NAME,
    payload: { value },
})

const setFileLoader = (value) => ({
    type: FILE_LOADER,
    payload: { value },
})

const setFileContent = (value) => ({
    type: FILE_CONTENT,
    payload: { value },
})

export const setInsertBatch = (value) => ({
    type: INSERT_BATCH,
    payload: { value }
}) 

export const sessionActions = [
    setUsername,
    setBatchID,
    setUserID,
    setFileName,
    setFileLoader,
    setFileContent,
    setInsertBatch
]
