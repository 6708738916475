import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { scrapeCompanyLocations } from '../services/company_locations'

export const useCompanyLocations = (companyName, apiVersion, companyLocationsConfig, hasBeenFetched = false) => {
    const [isLoading, setIsLoading] = useState(true)
    const [companyLocations, setCompanyLocations] = useState({})

    useEffect(() => {
        const scrapeLocations = async () => {
            if (!companyName) {
                console.error("Undefined 'companyName' variable.")
                toast.error("There is a problem with the server.")
                setIsLoading(false)
                return
            }

            try {
                if(!hasBeenFetched) {
                    const scrapedCompanyLocationsOrError = await scrapeCompanyLocations(companyName, apiVersion, companyLocationsConfig)
                    if (scrapedCompanyLocationsOrError.hasOwnProperty("error")) {
                        console.error(`Api response error: ${scrapedCompanyLocationsOrError["error"]}`)
                        toast.error("There is a problem with the server.")
                        return
                    } else {
                        setCompanyLocations(scrapedCompanyLocationsOrError)
                    }
                }
            } catch (error) {
                console.error(`Error with the request: ${error}`)
                toast.error("There is a problem with the server.")
            } finally {
                if(!hasBeenFetched) {
                    setIsLoading(false)
                }
            }
        }

        scrapeLocations()
    })

    return [isLoading, companyLocations]  
}
