export class Locations {
    static fromJsonToObject(jsonLocations) {
        return jsonLocations.map(jsonLocation => _Location.fromJson(jsonLocation))
    }   

    static getLocationsCoordinates(locations) {
        return locations.map(locations => [locations.latitude, locations.longitude])
    }

    static getLocationsInformation(locations) {
        return locations.map(location => 
            [
                location.location_name, 
                location.source_full_address, 
                location.country,
                location.source_link
            ]
        )
    }
}

class _Location {
    static fromJson(params) {
        return {
            country: params.country,
            latitude: params.lat, 
            longitude: params.long,
            location_name: params.location_name,
            source_link: params.source_link,
            source_full_address: params.source_full_address
        }
    }
}