// import axios from 'axios'
import { parseApiError } from '../utils/parsers/parseApiError';
import { companyLocationsScrapedMock } from '../mocks/company_locations_api_mock'

export const scrapeCompanyLocations = async (companyName, apiVersion, config) => {
    try {
        // const response = await axios.get(
        //     `${process.env.REACT_APP_CLOUD_IB_BASE_URL_ANALYTICS_FAST_API}/${apiVersion}/${companyName}/${process.env.REACT_APP_CLOUD_IB_ENDPOINT_SCRAPE_LOCATIONS}`,
        //     {
        //         params: config.params,
        //         timeout: config.timeout,
        //         headers: config.headers
        //     }
        // )
        // return response.data
        return companyLocationsScrapedMock
    } catch (error) {
        return parseApiError(error)
    }
}
