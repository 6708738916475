import { Header } from '../../Header/Header'

export const headerList = (rows, params, options) => {
    return rows.map((row, index) => (
        <Header 
            key={index}
            rows={row}
            params={params[index]}
            options={options[index]}
        />
    ))
}