import axios from 'axios'
import { parseApiError } from '../utils/parsers/parseApiError';
import { Token } from '../utils/authentication/auth';

export const fetchAllBatchesInWork = async (authContext, ...config) => {
    const jwt = await Token.refreshAccessTokenIfAuthenticated(authContext)

    const accessToken = jwt || authContext.jwt
    const headers = {
        ...config.headers ?? {},  
        ...(accessToken && { 'Authorization': `Bearer ${accessToken}` }), 
    }

    try {
        const response = await axios.get(
            `${process.env.REACT_APP_CLOUD_IB_BASE_URL_ANALYTICS_FAST_API}/${process.env.REACT_APP_CLOUD_IB_ENDPOINT_GET_BATCHES_FOR_COMBO_BOX}`,
            {
                params: config.params,
                timeout: config.timeout,
                headers: headers
            }
        )
        return response.data
    } catch (error) {
        return parseApiError(error)
    }
}

export const createBatch = async (authContext, config) => {
    const jwt = await Token.refreshAccessTokenIfAuthenticated(authContext)

    const accessToken = jwt || authContext.jwt
    const headers = {
        ...config.headers ?? {},  
        ...(accessToken && { 'Authorization': `Bearer ${accessToken}` }), 
    }

    const { payload } = config

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_CLOUD_IB_BASE_URL_ANALYTICS_FAST_API}/${process.env.REACT_APP_CLOUD_IB_ENDPOINT_CREATE_BATCH}`,
            payload,
            {
                headers: headers
            }
        )

        const data = response.data
        
        return {    
            success: true,
            message: data.detail,
            batchID: data.result.id
        }
    } catch (error) {
        return parseApiError(error)
    }
}