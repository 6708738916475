import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { additionalCompanyLocationsConfig } from '../../utils/constants';
import { CompanyLocationsGrid } from '../../components/CompanyLocationsGrid/CompanyLocationsGrid';
import { StringFormatter } from '../../utils/helpers/stringFormatter';
import { useCompanyLocations } from '../../hooks/useCompanyLocations';
import { CompanyLocations } from '../../utils/helpers/companyLocations';
import { headerList } from '../../components/Header/HeaderList/HeaderList';
import { VerticalPadding } from '../../components/VerticalPadding/VerticalPadding';
import { topCompanyLocationsPageRows } from '../../utils/helpers/companyLocationsPageRows';
import { handleSpecificInputChange } from '../../utils/helpers/handleSpecificInputChange';
import { onButtonRedirectHelper } from '../../utils/helpers/callbacks';
import { IFrameBox } from '../../components/IFrameBox/IFrameBox';
import { ListRow } from '../../components/ListRow/ListRow'
import { ObjectHelper } from '../../utils/helpers/object' 

export const CompanyLocationsPage = () => {
    const navigate = useNavigate()

    const [areCompanyLocationsFetched, setAreCompanyLocationsFetched] = useState(false)

    const [companyNameValue, isCompanyListBoxEnabled, toggleCompanyListBox] = useSelector(state => [
        state.header.companyInputValue,
        state.header.isCompanyListBoxEnabled,
        state.header.callbacks.toggleCompanyListBox
    ])
    
    const [comboBoxLabel, correctLocationsOptions, selectedCorrectLocationsValue] = useSelector(state => [
        state.header.comboBoxLabel,
        state.header.correctLocationsOptions,
        state.header.selectedCorrectLocations,
    ])
    const [selectedCorrectLocations, setSelectedCorrectLocations] = useState(selectedCorrectLocationsValue)
    
    const [commentLocationsInput, commentLocationsInputValueVisible] = useSelector(state => [
        state.header.commentLocationsInputValue,
        state.header.isCommentLocationsInputValueVisible
    ])
    const [commentLocationsInputValue, setCommentLocationsInputValue] = useState(commentLocationsInput)
    const [isCommentLocationsInputValueVisible, setIsCommentLocationsInputValueVisible] = useState(commentLocationsInputValueVisible)

    const [buttonSubmitToDatabaseLabel, onButtonSubmitToDatabase] = useSelector(state => [
        state.header.buttonLabel,
        state.header.callbacks.onButtonSubmitToDatabase,
    ])

    const location = useLocation()
    const { url, iFrameUrl, crawlerType } = location.state || { params: undefined }

    let [rows, columns] = [[], []]

    const companyLocationsConfig = {
        params: {
            url: url,
            crawler_type: crawlerType
        },
        ...additionalCompanyLocationsConfig
    }
    
    const [isLoading, companyLocationsPayload] = useCompanyLocations(
        StringFormatter.toSnakeCase(companyNameValue), 
        "api/v2", 
        companyLocationsConfig,
        areCompanyLocationsFetched
    )

    if (!isLoading && !ObjectHelper.isEmpty(companyLocationsPayload)) {
        [rows, columns] = CompanyLocations.toGridFormat(companyLocationsPayload)
    }

    const handleInputChange = (setter, ...callbacks) => (event) => {
        const { id, value } = event.target
        handleSpecificInputChange(id, value, ...callbacks)
        setter(value)
        if(!areCompanyLocationsFetched) {
            setAreCompanyLocationsFetched(true)
        }
    }

    const topHomePageParams = [
        [
            [companyNameValue, null, isCompanyListBoxEnabled, toggleCompanyListBox],
            [comboBoxLabel, correctLocationsOptions, selectedCorrectLocations, handleInputChange(setSelectedCorrectLocations, ...[null, setIsCommentLocationsInputValueVisible])],
            [commentLocationsInputValue, handleInputChange(setCommentLocationsInputValue), isCommentLocationsInputValueVisible],
        ],
        [
            [buttonSubmitToDatabaseLabel, onButtonSubmitToDatabase],
            [],
            ["Go To Previous Page",  () => {
                onButtonRedirectHelper({
                    navigate: navigate,
                    location: "/home_page",
                })
            }],
        ]
    ]

    return (
        <div>
            <VerticalPadding paddingSizeType="small" />
            <div>
                {headerList(
                    [topCompanyLocationsPageRows],
                    [topHomePageParams],
                    [
                        { container: "top-flex-container", row: "top-flex-row", item: "top-flex-item" },
                        { container: "top-down-grid-container", row: "top-down-grid-row", item: "top-down-grid-item" }
                    ],
                ).map((headerItem, index) => (
                    <div key={index}>{headerItem}</div>
                ))}
                <VerticalPadding paddingSizeType="small" />
                <ListRow 
                    components={[
                        <CompanyLocationsGrid
                            columns={columns}
                            rows={rows}
                            config={{ isLoading }}
                            width='100%'
                            height='50%'
                        />,
                        <IFrameBox
                            src={iFrameUrl}
                            title="iframe-link-displayer"
                            width="80vw"
                            height="70vh"
                        /> 
                    ]}
                    data={[columns, iFrameUrl]}
                />
            </div>
            <VerticalPadding paddingSizeType="small" />
            <ToastContainer />
        </div>
    )
}