import {
    SET_COMPANY_INPUT_VALUE,
    SET_SELECTED_CORRECT_LOCATIONS,
    SET_COMMENT_LOCATIONS_INPUT_VALUE,
    ON_BUTTON_SUBMIT_TO_DATABASE,
} from './headerActionTypes';

const setCompanyInputValue = (value, flag, callbackFlag) => ({
    type: SET_COMPANY_INPUT_VALUE,
    payload: { value, flag, callbackFlag },
})

const setSelectedCorrectLocations = (label, options, value) => ({
    type: SET_SELECTED_CORRECT_LOCATIONS,
    payload: { label, options, value },
})

const setCommentLocationsInputValue = (value, flag) => ({
    type: SET_COMMENT_LOCATIONS_INPUT_VALUE,
    payload: { value, flag },
})

const setOnButtonSubmitToDatabase = (label, callback) => ({
    type: ON_BUTTON_SUBMIT_TO_DATABASE,
    payload: { label, callback },
})

export const headerActions = [
    setCompanyInputValue,
    setSelectedCorrectLocations,
    setCommentLocationsInputValue,
    setOnButtonSubmitToDatabase
]