// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ListRow_top-flex-container-down__1s-U1 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;
    box-sizing: border-box;
}

.ListRow_top-flex-item-down__YosuB {
    flex: 1 1;
    max-width: 100%;
    min-width: 300px;
}

.ListRow_map-container__72aQt {
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;
    height: 230px;
    margin: 0;
    padding: 0;
}

.ListRow_item-padding__TwegC {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    height: 70vh;
    color: #888;
    font-size: 1.2rem;
}

@media (max-width: 900px) {
    .ListRow_map-container__72aQt {
        height: 40%;
        padding-bottom: 90px;
    }
}

@media (max-width: 480px) {
    .ListRow_map-container__72aQt {
        height: 30%;
        padding-bottom: 120px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/ListRow/ListRow.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,uBAAuB;IACvB,WAAW;IACX,eAAe;IACf,SAAS;IACT,sBAAsB;AAC1B;;AAEA;IACI,SAAO;IACP,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,eAAe;IACf,aAAa;IACb,SAAS;IACT,UAAU;AACd;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;IACZ,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI;QACI,WAAW;QACX,oBAAoB;IACxB;AACJ;;AAEA;IACI;QACI,WAAW;QACX,qBAAqB;IACzB;AACJ","sourcesContent":[".top-flex-container-down {\n    display: flex;\n    justify-content: space-between;\n    align-items: flex-start;\n    width: 100%;\n    flex-wrap: wrap;\n    gap: 20px;\n    box-sizing: border-box;\n}\n\n.top-flex-item-down {\n    flex: 1;\n    max-width: 100%;\n    min-width: 300px;\n}\n\n.map-container {\n    display: flex;\n    align-items: flex-start;\n    width: 100%;\n    max-width: 100%;\n    height: 230px;\n    margin: 0;\n    padding: 0;\n}\n\n.item-padding {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    max-height: 100%;\n    height: 70vh;\n    color: #888;\n    font-size: 1.2rem;\n}\n\n@media (max-width: 900px) {\n    .map-container {\n        height: 40%;\n        padding-bottom: 90px;\n    }\n}\n\n@media (max-width: 480px) {\n    .map-container {\n        height: 30%;\n        padding-bottom: 120px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top-flex-container-down": `ListRow_top-flex-container-down__1s-U1`,
	"top-flex-item-down": `ListRow_top-flex-item-down__YosuB`,
	"map-container": `ListRow_map-container__72aQt`,
	"item-padding": `ListRow_item-padding__TwegC`
};
export default ___CSS_LOADER_EXPORT___;
